import {
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Paper,
  Select,
  TablePagination,
  Typography,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import { dummyArticles } from "../../../utils/riskUtils/riskUtils";

const useStyles = makeStyles(() => ({
  topicStyle: {
    width: "40px",
    height: "40px",
    marginTop: "5px",
    position: "relative",
    top: "5px",
  },
  mainGrid: {
    textAlign: "center",
  },
  scoreImage: {
    width: "180px",
    height: "160px",
  },
  maxScoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
  },
  scoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "xx-large",
  },
  sharesurvey: {
    color: "#3374B9",
    fontWeight: 600,
  },
  describe: {
    color: "grey",
    fontSize: "x-small",
    marginTop: "11px",
  },
  Table: {
    outline: "0.5px solid lightGrey",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px",
    height: "100%",
  },
  TableContainer: {
    padding: "3px",
  },
  TableCell: {
    padding: "5px",
    border: "none",
  },
  hedaderCell: { color: "grey", fontSize: "x-small" },
  topicCell: { padding: "4px 4px" },
  lableCell: {
    // width: '193px',
    // height: '20px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    // lineHeight: "20px",
  },
  scoreCell: {
    textAlign: "right",
    // width: '32px',
    // height: '28px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "28px",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    // height:'100%'
  },
  cardContent: {
    backgroundColor: "#FFFFFF",
    padding: "10px",
  },
  LinearProgress: {
    // color:'success.main',
    margin: "-25px 10px 25px 10px",
  },
  progressIndicator1: {
    position: "relative",
    left: "-18%",
    top: "12px",
    backgroundColor: "#3374B9",
    zIndex: 1,
  },
  progressIndicator2: {
    position: "relative",
    left: "17.7%",
    top: "12px",
    backgroundColor: "#3374B9",
    zIndex: 1,
  },
  upColor: {
    border: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginRight: "5px",
    backgroundColor: "green",
  },
  flatColor: {
    border: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginRight: "5px",
    backgroundColor: "yellow",
  },
  downColor: {
    border: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginRight: "5px",
    backgroundColor: "red",
  },
  arrowUp: { width: "20px", height: "20px", color: "#fff", padding: "3px" },
}));

function NewsArticle(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startingAfter, setStartingAfter] = useState(0);
  const [newsArticleSort, setNewsArticleSort] = useState("Positive On Top");

  const { riskEvaluator, getNewArticles, login, brmData } = props;
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    setStartingAfter(newPage * rowsPerPage);
    // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // getNewArticles(
    //   login.token,
    //   brmData?.organizationDetails[0]?.isin,
    //   rowsPerPage,
    //   startingAfter
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    // setPages(Math.ceil(riskEvaluator?.totalArticles|| 0 / rowsPerPage));
  }, [riskEvaluator?.totalArticles]);
  return (
    <div>
      {/* // <div className="scroll" style={{ height: "54vh" }}> */}
      {false && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {" "}
            <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
              News Article
            </Typography>
          </div>
        </div>
      )}
      <div>
        <Grid item md={12} style={{ padding: "5px" }} component={Paper}>
          {false && (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              md={12}
            >
              <Grid item>
                <Typography>News Article</Typography>
              </Grid>
              <Grid item alignItems="center">
                <FormControl style={{ color: "#3374B9", width: "250px" }}>
                  <Select
                    style={{
                      border: "1px solid #C0D4E9",
                      backgroundColor: "#FEFEFE",
                      borderRadius: "8px",
                      height: "40px",
                      marginTop: "15px",
                    }}
                    value={newsArticleSort}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      setNewsArticleSort(value);
                    }}
                    input={<OutlinedInput />}
                  >
                    {["Positive On Top", "Negative On Top"].map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Icon style={{ marginTop: "22px" }}>more_vert</Icon>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: "10px",
              backgroundColor: "#EBF1F8",
              padding: "3px",
              borderRadius: "5px",
            }}
          >
            <div style={{ width: "22%" }}>
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                Organization
              </Typography>
            </div>
            <div style={{ width: "36%" }}>
              <Typography style={{ fontSize: "13px" }}>
                {" "}
                Title / Time
              </Typography>
            </div>
            <div style={{ width: "29%" }}>
              <Typography style={{ fontSize: "13px" }}>Source</Typography>
            </div>
            <div style={{ width: "13%" }}>
              <Typography style={{ fontSize: "13px" }}>Link</Typography>
            </div>
          </Grid>
          {props?.articles &&
            Array.isArray(props?.articles) &&
            props?.articles
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <Grid
                    container
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      marginTop: "10px",
                      padding: "5px",
                      borderBottom: "1px solid grey",
                      borderRadius: "2px",
                      alignItems: "center",
                      width: "100%",
                      gap: 5,
                    }}
                  >
                    <div style={{ width: "20%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={
                            row.sentiment_label === "Positive"
                              ? classes.upColor
                              : row.sentiment_label === "Neutral"
                              ? classes.flatColor
                              : row.sentiment_label === "Negative"
                              ? classes.downColor
                              : null
                          }
                        >
                          {row.sentiment_label === "Positive" ? (
                            <ArrowUpwardIcon className={classes.arrowUp} />
                          ) : row.sentiment_label === "Neutral" ? (
                            <RemoveIcon className={classes.arrowUp} />
                          ) : row.sentiment_label === "Negative" ? (
                            <ArrowDownwardIcon className={classes.arrowUp} />
                          ) : null}
                        </div>
                        <Typography style={{ fontSize: "13px" }}>
                          {row.companyname}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ width: "37%" }}>
                      <Typography
                        style={{ fontSize: "13px", marginLeft: "5px" }}
                      >
                        {row.title}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          marginLeft: "5px",
                          color: "grey",
                        }}
                      >
                        {moment(row.seendate).format("lll")}
                      </Typography>
                    </div>

                    <div style={{ width: "26%" }}>
                      <Typography style={{ fontSize: "13px" }}>
                        {row.domain}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        width: "15%",
                      }}
                      aligm="right"
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          padding: "5px 5px 5px 10px",
                          fontSize: "18px",
                        }}
                        href={row.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`>`}
                      </a>
                      <Typography
                        style={{
                          fontSize: "13px",
                          // textAlign: "right",
                          wordBreak: "break-word",
                        }}
                      >
                        View Article
                      </Typography>
                    </div>
                    {/* <Grid
                      style={{ textAlign: "center" }}
                      item
                      md={1}
                      sm={1}
                      xs={1}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          padding: "5px",
                          fontSize: "18px",
                        }}
                        href={row.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`>`}
                      </a>
                    </Grid> */}
                  </Grid>
                );
              })}

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={riskEvaluator?.totalArticles || 5}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </div>
    </div>
  );
}

export default NewsArticle;
