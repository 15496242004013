import UNIVERSAL from "../../config/config";
import {
  RESET_ESG_STRATEGY,
  SET_TOPIC_SELECTED,
  SET_TOPIC_DETAILS,
  SET_TOPIC_STATE,
  SET_CURRENT_TOPIC,
  SET_IS_EDIT_ACTIVITY,
  SET_IS_EDIT_KPI,
  SET_ASSIGNED_TOPICS,
  SET_ASSIGNED_MEMBERS,
  SET_UNITS,
  SET_CONTRO_INSIGHTS,
} from "../../constants/esgStategy/esgStrategyConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";

export function setTopicState(payload) {
  return {
    type: SET_TOPIC_STATE,
    payload: payload,
  };
}

export function setCurrentTopic(payload) {
  return {
    type: SET_CURRENT_TOPIC,
    payload: payload,
  };
}

export function resetESGStrategy() {
  return {
    type: RESET_ESG_STRATEGY,
  };
}

export const setSelectedTopics = (payload) => ({
  type: SET_TOPIC_SELECTED,
  payload: payload,
});

export const setTopicDetails = (payload) => ({
  type: SET_TOPIC_DETAILS,
  payload: payload,
});

export const setIsEditActivity = (payload) => ({
  type: SET_IS_EDIT_ACTIVITY,
  payload: payload,
});

export const setIsEditKPI = (payload) => ({
  type: SET_IS_EDIT_KPI,
  payload: payload,
});

export const setAssignedTopics = (payload) => ({
  type: SET_ASSIGNED_TOPICS,
  payload: payload,
});

export const setAssignedMembers = (payload) => ({
  type: SET_ASSIGNED_MEMBERS,
  payload: payload,
});

export const setUnits = (payload) => {
  return { type: SET_UNITS, payload: payload };
};

export const getSelectedTopics = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_topics_seleceted", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSelectedTopics(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getTopicDetails = (
  token,
  assessment_topic_id,
  my_task_section
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_topic_id,
      my_task_section,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/get_topic_details", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status && responseJson.result !== null) {
          dispatch(setTopicDetails(responseJson.result));
        } else {
          dispatch(setTopicDetails(""));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const addActivity = (
  token,
  assessment_topic_id,
  activity_title,
  estimated_period,
  budget,
  comments,
  file,
  assessment_id,
  my_task_section,
  currency,
  topic_name
) => {
  var formData = new FormData();
  const data = encrypt({
    "user-token": token,
    assessment_topic_id,
    activity_title,
    estimated_period,
    budget,
    comments,
    assessment_id,
    currency,
    organisation_id: localStorage.getItem("current_organisation"),
    topic_name,
  });
  formData.append("data", data);
  formData.append("file", file);
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/topic/add_activity", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(setTopicState(0));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const editActivity = (
  token,
  assessment_topic_id,
  activity_id,
  activity_title,
  estimated_period,
  budget,
  comments,
  file,
  assessment_id,
  my_task_section,
  currency,
  topic_name
) => {
  var formData = new FormData();
  const data = encrypt({
    "user-token": token,
    assessment_topic_id,
    activity_id,
    activity_title,
    estimated_period,
    budget,
    comments,
    assessment_id,
    currency,
    organisation_id: localStorage.getItem("current_organisation"),
    topic_name,
  });

  formData.append("data", data);
  formData.append("file", file);
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/topic/edit_activity", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(setTopicState(0));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const deleteActivity = (
  token,
  assessment_topic_id,
  activity_id,
  my_task_section,
  topic_name,
  assessment_id
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_topic_id,
      activity_id,
      topic_name,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/delete_activity", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const addKPI = (
  token,
  assessment_topic_id,
  kpi_title,
  baseline,
  target,
  unit,
  estimated_period,
  budget,
  comments,
  file,
  assessment_id,
  my_task_section,
  currency,
  topic_name
) => {
  var formData = new FormData();

  const data = encrypt({
    "user-token": token,
    assessment_topic_id,
    kpi_title,
    baseline,
    target,
    unit,
    estimated_period,
    budget,
    comments,
    assessment_id,
    currency,
    organisation_id: localStorage.getItem("current_organisation"),
    topic_name,
  });
  formData.append("data", data);
  formData.append("file", file);
  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/topic/add_kpi", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(setTopicState(0));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const editKPI = (
  token,
  assessment_topic_id,
  kpi_id,
  kpi_title,
  baseline,
  target,
  unit,
  estimated_period,
  budget,
  comments,
  file,
  assessment_id,
  my_task_section,
  currency,
  topic_name
) => {
  var formData = new FormData();

  const data = encrypt({
    "user-token": token,
    assessment_topic_id,
    kpi_id,
    kpi_title,
    baseline,
    target,
    unit,
    estimated_period,
    budget,
    comments,
    assessment_id,
    currency,
    organisation_id: localStorage.getItem("current_organisation"),
    topic_name,
  });
  formData.append("data", data);
  formData.append("file", file);

  return (dispatch) => {
    dispatch(set_loader());

    return fetch(UNIVERSAL.BASEURL + "/topic/edit_kpi", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(setTopicState(0));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const deleteKPI = (
  token,
  assessment_topic_id,
  kpi_id,
  my_task_section,
  topic_name,
  assessment_id
) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_topic_id,
      kpi_id,
      topic_name,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/topic/delete_kpi", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(getTopicDetails(token, assessment_topic_id, my_task_section));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function assignMembers(
  token,
  email_ids,
  step_no,
  assessment_id,
  organisation_id,
  topic_id,
  role,
  mailSubject,
  content
) {
  let url = window.origin;
  let domain_name = window.location.hostname;
  const data = encrypt({
    "user-token": token ? token : localStorage.getItem("user_token"),
    email_ids,
    step_no,
    assessment_id,
    organisation_id,
    topic_id,
    role,
    mailSubject,
    content,
    url,
    domain_name,
  });
  console.log("action", mailSubject, content);
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/assignMembers/assign_members", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
}

export function viewAssignMembers(token, assessment_id, step_no, topic_id) {
  const data = encrypt({
    "user-token": token,
    assessment_id,
    step_no,
    topic_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/assignMembers/view_assigned_members", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAssignedMembers(responseJson.result));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(unset_loader()));
  };
}

export const getAssignedTopics = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      "user-token": token,
      assessment_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/assessments/get_assigned_topics", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAssignedTopics(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export function deleteAssignedMember(
  token,
  stakeholder_id,
  assessment_id,
  step_no,
  topic_id
) {
  return (dispatch) => {
    let organisation_id = localStorage.getItem("current_organisation");
    const data = encrypt({
      "user-token": token,
      stakeholder_id,
      organisation_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/delete_stakeholder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewAssignMembers(token, assessment_id, step_no, topic_id));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function getUnit() {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/topic/get_units", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setUnits(responseJson.result));
        }
      })
      .catch((err) => console.log(err));
  };
}

export function addUnit(token, unit) {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      unit,
    });
    return fetch(UNIVERSAL.BASEURL + "/topic/add_unit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getUnit());
        }
      })
      .catch((err) => console.log(err));
  };
}

// ---------------- controversy apis ----------------

const setControInsights = (surveys) => {
  return { type: SET_CONTRO_INSIGHTS, payload: surveys };
};

export const rest_get_contro_insights = (isin) => {
  return async (dispatch, getState) => {
    dispatch(set_loader());

    const token = getState().login?.token;

    try {
      const data = encrypt({
        "user-token": token,
        isin,
      });

      const res = await fetch(
        `${UNIVERSAL.ESG_DIAGNOSIS_REST}/elastic_search2/get_controversy_chart_data`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      );

      const result = await res.json();

      // dispatch(
      //   setControInsights({
      //     final_dates: [
      //       {
      //         key_as_string: "2022-01-01",
      //         key: 1640995200000,
      //         doc_count: 7,
      //         month: "January",
      //       },
      //       {
      //         key_as_string: "2022-12-01",
      //         key: 1669852800000,
      //         doc_count: 2,
      //         month: "December",
      //       },
      //     ],
      //     countries: [
      //       {
      //         key: "India",
      //         doc_count: 9,
      //       },
      //     ],
      //   })
      // );
      dispatch(setControInsights(result?.result));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(unset_loader());
    }
  };
};
